<template>
  <b-card>
    <b-form-group>
      <label>Current Password</label>
      <b-form-input
        v-model="internalValue.current_password"
        type="password"
      />
    </b-form-group>

    <b-form-group>
      <label>New Password</label>
      <b-form-input
        v-model="internalValue.password"
        type="password"
      />
    </b-form-group>

    <b-form-group>
      <label>Confirm Password</label>
      <b-form-input
        v-model="internalValue.password_confirmation"
        type="password"
      />
    </b-form-group>

    <!-- <b-form-group>
      <b-form-checkbox v-model="internalValue.logout_other_devices" switch>
        Logout other devices?
      </b-form-checkbox>
    </b-form-group> -->
  </b-card>
</template>

<script>
export default {
  name: 'PasswordForm',

  props: {
    value: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      internalValue: {
        // logout_other_devices: true,
        current_password: '',
        password: '',
        password_confirmation: ''
      }
    }
  },

  mounted () {
    if (this.value) {
      this.internalValue = JSON.parse(JSON.stringify(this.value))
    }
    this.emitInput()
  },

  methods: {
    emitInput () {
      this.$emit('input', this.internalValue)
    },

    reset () {
      this.internalValue = {
        current_password: '',
        password: '',
        password_confirmation: ''
      }
      this.emitInput()
    }
  }
}
</script>

<style>

</style>