<template>
  <div class="change-password-page">
    <h2>Change Password</h2>

    <password-form
      v-model="form"
      class="my-3"
      ref="form"
    />

    <b-button @click="submit">Save</b-button>
  </div>
</template>

<script>
import PasswordForm from '../components/PasswordForm.vue'

export default {
  name: 'PasswordChange',

  components: {
    PasswordForm
  },

  data () {
    return {
      form: null
    }
  },

  methods: {
    validate () {
      const valid = true
      return valid
    },

    async submit () {
      if (!this.validate()) {
        console.warn('Invalid form')
        return
      }

      try {
        const data = {
          // logout_other_devices: this.form.logout_other_devices,
          current_password: this.form.current_password,
          password: this.form.password,
          password_confirmation: this.form.password_confirmation
        }
        await this.$profile.updatePassword(data)
        this.$refs.form.reset()
      }
      catch (e) {
        console.error(e.message)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
